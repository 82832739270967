import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import General from "./General";
import Obd from "./Obd";
import CanAdapter from "./CanAdapter";
import Can from "./Can";
import Sim from "./Sim";
import Accessories from "./Accessories";
import Carrier from "./Carrier";
import Loader from "./Loader";
import { DetailsData, DetailsProps } from "./types";

import DetailsContainer from "../../components/DetailsContainer";

import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    useApi,
    useIsMounted
} from "../../../../shared";

const Details = ({ imei }: DetailsProps) => {
    const { isAdminUser, isCarrierUser } = useContext(AuthContext);
    const { detailsData, setDetailsData } = useContext(TableContext);

    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const {
        obd,
        can_adapter,
        gsm_number,
        iccid,
        imsi,
        accessories,
        carrier,
        can,
        can_package
    }: DetailsData = detailsData;

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const getAdditionalData = async () => {
            try {
                setLoading(true);

                const {
                    data: { can, can_package, dtb_version }
                } = await getData(`${ENDPOINTS.Devices}/${imei}`);

                if (isMounted) {
                    setDetailsData({
                        ...detailsData,
                        can,
                        can_package,
                        dtb_version
                    });
                }
            } catch (error) {
                if (isMounted && !isCanceled) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getAdditionalData();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    const renderCan =
        !!can_package ||
        !!can?.can_package ||
        !!can?.chip_version ||
        !!can?.vehicle ||
        !!can?.vehicle_id ||
        !!can?.can_package_id;

    const renderSim = gsm_number || iccid || imsi;

    const renderCarrier = (isAdminUser || isCarrierUser) && carrier;

    return (
        <DetailsContainer>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Grid item xs0={12} md={6} lg={4}>
                        <General />
                    </Grid>

                    {obd && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <Obd />
                        </Grid>
                    )}

                    {can_adapter && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <CanAdapter />
                        </Grid>
                    )}

                    {renderCan && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <Can />
                        </Grid>
                    )}

                    {renderSim && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <Sim />
                        </Grid>
                    )}

                    {accessories && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <Accessories />
                        </Grid>
                    )}

                    {renderCarrier && (
                        <Grid item xs0={12} md={6} lg={4}>
                            <Carrier />
                        </Grid>
                    )}
                </>
            )}
        </DetailsContainer>
    );
};

export default Details;
