/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Grid from "@mui/material/Grid";

import { RectangleLoader } from "../../../../../shared";

const SingleLoader = () => {
    return (
        <Grid
            item
            xs0={12}
            md={6}
            lg={4}
            css={css({
                height: "214px",

                "& > span:first-of-type": {
                    marginBottom: "18px"
                }
            })}
        >
            <RectangleLoader width={216} height={20} borderRadius="46px" />
            <RectangleLoader width={129} height={20} borderRadius="46px" />
        </Grid>
    );
};

export default SingleLoader;
